import * as utils from '../../utilities/utils.js'

import moment from 'moment';
import Axios from 'axios';
import { SmartRequests } from '../../utilities/index.js';

export function get_pages(root, tags, all_pages) {
  return tags[root].map( pId => (
    {
      page: all_pages[pId],
      subPages: tags[all_pages[pId].name]? 
        get_pages(all_pages[pId].name, tags, all_pages) : 
        []
    })
  ).sort((a,b) => a.page.show_order - b.page.show_order )
}

export function insertReservation(reservations, newRes){
    var i = 0
    var resDate = moment(newRes.start_date)
    while (i < reservations.length && moment(reservations[i].start_date) < resDate) { i++ }
    reservations.splice(i, 0, newRes)
}

// ----------------------------------------------- Widget Functions ------------------------------------------------
 
 export const WIDGET_FUNCTIONS = {

  messages:   function(){
    return this.state.messages.reduce((old,msg) => old + (msg.status === 0 | 0), 0) || ''
  },

  deliveries: function(){
    return this.state.deliveries.total_nof_elements || ''
  },

  valet:      function(){
    return this.state.vehicles.reduce((old,car) => old + (car.state === 'ready' | 0), 0) || ''
  },

  weather:    function(){

  },

  polls: function(){
    return this.state.polls.unanswered || ''
  },

}
