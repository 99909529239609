import React from 'react';
import MarkdownRenderer from '../../MDRenderer';
import { Form, Switch, Button, message, Modal, Spin } from 'antd';
import { injectIntl } from "react-intl";

import { SmartRequests } from '../../../utilities/index.js';
import { CustomField } from '../Custom/CustomField';
import CustomFormParser from '../Custom/parser';
import { withRouter } from 'react-router-dom';
import ItemAccess from './ItemAccess';
import { PlusCircleOutlined } from '@ant-design/icons';
import msg from './messages';

const FormItem = Form.Item

const formItemLayout = {
}

let formDataAuth = {
  type_of_timeframe: "once",
  start_date: "",
  end_date: "",
  time_start: "",
  time_finish: "",
  days_of_week: [],
  days_of_month: [],
};


class GuestForm extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      itemAccessForDay: [],
      redirect: false,

      isModalVisible: false,
      guest_auth_list:[],

    }
    this.dataGuestEdit = formDataAuth;
    this.cont = 1;
  }

  componentWillMount = () => {
    console.log("Mounting guest form")
  }

  computeStateFromProps(props) {
    console.log("GuestForm: componentDidMount")
    this.t = props.intl.formatMessage
    const parser = new CustomFormParser(props.formRaw);
    let form = parser.parseAll();
    
    this.current_type_of_page = props.type_of_page
    this.current_id_guest = props.match.params.id_guest
    const  type_of_page  = this.current_type_of_page;
    const  id_guest  =  this.current_id_guest;
    this.setState({ typeOfPage: type_of_page, id_guest});

    if (type_of_page === "add-new-guest") {
      this.setState({isLoading: false});
      this.setFormData(form);
    } else {
      if (type_of_page === "edit-guest"){
        this.initialDataEditGuest(form, id_guest);
      } else {
        if (type_of_page === "edit-auth"){
          let guest = {}, auth_list = {};
          this.initialDataEditAuths(guest, auth_list, form, id_guest);
        }
      }
    }
  }

  componentDidMount = () => {
    console.log("GuestForm: componentDidMount")
    this.computeStateFromProps(this.props)
  }

  componentWillReceiveProps(nextProps) {
    console.log("GuestForm: componentWillReceiveProps",this.props, nextProps)
    if (this.current_type_of_page !== nextProps.type_of_page || this.current_id_guest !== nextProps.match.params.id_guest) {
      this.computeStateFromProps(nextProps);
    }
  }

  initialDataEditGuest = (form, id_guest) => {
    this.setFormData(form);
    this.setState({isLoading: true})
    SmartRequests.get(`guest_authorization/${id_guest}`).then( resp => {
      if (resp.status === 200) {
        this.setState({isLoading: false})
        this.guest = resp.data.guest;
        form = this.updateForm(form, resp.data.guest);
        this.setFormData(form);
      }
    }).catch( err => { 
        console.log("Error: " + err);
        message.error(err.response.data.error);
    });
  }

  initialDataEditAuths = (guest, auth_list, form, id_guest) => {
    this.setFormData(form);
    this.setState({isLoading: true})
    SmartRequests.get(`guest_authorization/${id_guest}/auths`).then( resp => {
      if (resp.status === 200) {
        this.setState({isLoading: false})
        if (this.props.source_page === "add-page" || this.props.source_page === "edit-page") {
          auth_list = resp.data.guest_auth_list;
          guest.authorization_type = "recurrence";
        } else {
          auth_list = resp.data.guest_auth_list
          guest.authorization_type = auth_list[0].authorization_type;
        }
        form = this.updateForm(form, guest);
        if (auth_list[0].timeframe_id !== null){
          this.setState({guest_auth_list: auth_list});
        }
        this.setFormData(form);
      }
    }).catch( err => { 
        console.log("Error: " + err);
        message.error(err.response.data.error)
    });
  }

  setFormData = (form) => {
    this.setState({
      formData: form,
      redirect: false,
    })
  }

  requiredInputRules(){
    return [
      {
        required: true,
        message: 'This field is required!',
      },
    ]
  }

  updateForm(form, dataGuest){
    const updatedForm = form.map(item => {
      const prop = item[0];
      if (dataGuest.hasOwnProperty(prop)) {
        item[1].value = dataGuest[prop];
      }
      return item;
    });

    return updatedForm;
  }


  createInput = (input, index) => {
    const { getFieldDecorator } = this.props.form;
    const REQUIRED_MESSAGE = ' _(required)_';
    let info = input[1]
    let name = input[0] + (info.required ? REQUIRED_MESSAGE : '')
    let nameLabel = input[0];
    let field;


    if (info.type === 'markdown') {
      return <MarkdownRenderer markdown={name} />;
    }

    if (info.type === 'dropdown') {
      field = (
        <CustomField type="select"  onChange={this.changeHandler.bind(this, index, info.type)} options={info.options} />
      )
    } else if (info.type === 'textarea') {
      field = (
        <CustomField type={info.type} rows={3} id={'input_' + index} name={name}  onChange={this.changeHandler.bind(this, index, info.type)}/>
      )
    } else if (info.type === 'textbox') {
      field = (
        <CustomField id={'input_' + index} name={name}  onChange={this.changeHandler.bind(this, index, info.type)}/>
      )
    } else if (info.type === 'checkbox') {
      field = (
        <Switch id={'input_' + index} name={name} defaultChecked={true} onChange={this.changeHandler.bind(this, index, info.type)} />
      )
    } else if (info.type === 'date') {
      field = (
        <CustomField id={'input_' + index} name={name}  onChange={this.changeHandler.bind(this, index, info.type)} type={info.type} />
      );
    } else if (info.type === 'time') {
      field = (
        <CustomField
          type={info.type}
          id={'input_' + index}
          name={name}
          format="HH:mm"
          onChange={this.changeHandler.bind(this, index, info.type)}
        />
      )
    }

    if (field) {
      return (
        <FormItem label={ <MarkdownRenderer className="form-label" markdown={this.t(msg[nameLabel])}/>} {...formItemLayout} key={"guestFormItem-" + index} >
          {info.required ? getFieldDecorator(name, {rules: this.requiredInputRules(), initialValue:info.value})(field) : getFieldDecorator(name, {initialValue:info.value})(field)}
        </FormItem>
      );
    }

    return ''
  }

  submitHandler = (e, type_of_method="save_guest", source_page) => {
    e.preventDefault()
    
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.submitAddGuest(type_of_method, source_page);
      } else {
        console.log(`Error while submiting form: ${err}`);
      }
    })
  }

  loadFormData = (data) => {
    this.state.formData.forEach(el => {
      const name = el[0]
      const options = el[1]
      if (options.type !== 'markdown') {
        data[name] = options.value;
      }
    })
    return data
  }

  postSaveGuest = (data) => {
    SmartRequests.post('guest_authorization', data ).then( (response) => {
      this.props.updateGuests(response.data)
      this.props.history.goBack()
      this.props.block(false)
      message.success(this.t(msg.guestSaveSuccessfully));
    }).catch(error => {
      message.error(error.response.data.error)
      this.props.block(false)
    })
  }

  postSaveAndContinue = (data, source_page) => {
    SmartRequests.post('guest_authorization', data ).then( (response) => {
      this.props.updateGuests(response.data)
      
      this.props.history.replace({
        pathname:`/${this.props.building}/${this.props.unit}/dashboard/guest_auth/edit-guest/${response.data.guest_id}`,
        state: {
          guest: response.data,
        }
      })
      this.props.history.push({
        pathname: `/${this.props.building}/${this.props.unit}/dashboard/guest_auth/edit-guest/${response.data.guest_id}/edit-auth`,
        state: {
          guest: {"guest_name": data.guest_name},
          type_of_page: "edit-auth",
          source_page: source_page,
        }
      });
      this.props.block(false)
      message.success(this.t(msg.guestSaveSuccessfully))
    }).catch(error => {
      message.error(error.response.data.error)
      this.props.block(false)
    })
  }

  putUpdateAndContinue = (data, source_page) => {
    SmartRequests.put(`guest_authorization/${this.state.id_guest}`, data ).then( (response) => {
      this.props.updateGuests(response.data)
      this.props.history.push({
        pathname: `/${this.props.building}/${this.props.unit}/dashboard/guest_auth/edit-guest/${this.state.id_guest}/edit-auth`,
        state: {
          guest: {"guest_name": data.guest_name},
          type_of_page: "edit-auth",
          source_page: source_page,
        }
      });
      this.props.block(false)
      message.success(this.t(msg.guestSaveSuccessfully))
    }).catch(error => {
      message.error(error.response.data.error)
      this.props.block(false)
    })
  }

  submitAddGuest = (type_of_method, source_page) => {
    this.props.block(true);
    let data = {};

    data = this.loadFormData(data);

    if (type_of_method === "save_guest") {
      this.postSaveGuest(data);
    } else {
      if (type_of_method === "save_and_continue"){
        data.authorization_type = "not_allowed";
        this.postSaveAndContinue(data, source_page);
      } else {
        if (type_of_method === "update_and_continue"){
          this.putUpdateAndContinue(data, source_page)
        }
      }
    }
  }

  submitEditGuest = (id_guest) => {
    if (this.checkRequiredFields()){
      let data = this.guest;
      if ("id" in data) delete data.id;
  
      this.state.formData.forEach(el => {
        const name = el[0]
        const options = el[1]
        if (options.type !== 'markdown') {
          data[name] = options.value;
        }
      })
  
      SmartRequests.put(`guest_authorization/${id_guest}`, data ).then( (response) => {
        this.props.updateGuests(response.data)
        this.props.history.goBack()
        this.props.block(false)
        message.success(this.t(msg.SuccessRequest))
      }).catch(error => {
        message.error(error.response.data.error)
        this.props.block(false)
      })
    } else {
      message.error(this.t(msg.requiredFields))
    }
  }



  changeHandler = (i, type, value) => {
    let index = i
    this.setState( old => {
      let newData = old.formData.slice()
      newData[index][1].value = value
      return {formData: newData, cacho: old.cacho?old.cacho+1:1}
    })
  }

  handleChangeDateEntries = (e, id) => {
    const { name, value } = e.target;
    this.setState(prevState => {
      const updatedItemAccessForDay = prevState.itemAccessForDay.map(item => {
        if (item.id === id) {
          return { ...item, [name]: value };
        }
        return item;
      });

      return { itemAccessForDay: updatedItemAccessForDay };
    });
  };

  handleChangeDaysArray = (arr, type, id) => {
    this.setState(prevState => {
      const updatedItemAccessForDay = prevState.itemAccessForDay.map(item => {
        if (item.id === id) {
          return { ...item, [type]: arr };
        }
        return item;
      });

      return { itemAccessForDay: updatedItemAccessForDay };
    });
  }

  deleteItem = (idDel) => {
    const updatedItems = this.state.itemAccessForDay.filter(item => item.id !== idDel);
    this.setState({ itemAccessForDay: updatedItems });
  }

  editFormModal = (id) => {
    this.dataGuestEdit = this.state.guest_auth_list[id];
    this.action = "edit"
    this.showModalForm = true;
    this.setState({
      isModalVisible: true,
    });
  }

  showFormModal = () => {
    this.showModalForm = true;
    this.action = "add"
    this.setState({
      isModalVisible: true,
    });
    this.dataGuestEdit = formDataAuth;
  }

  handleCancelModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  deleteTimeFrameGuest = (index, timeframe_id) => {
    SmartRequests.Delete('guest_authorization/' + this.state.id_guest + '/' + timeframe_id ).then( (response) => {
      this.props.block(false);
      const updatedGuestsAuth = this.state.guest_auth_list.slice();
      if (index >= 0 && index < updatedGuestsAuth.length) {
        updatedGuestsAuth.splice(index, 1);
      }
      this.setState({guest_auth_list: updatedGuestsAuth });
      message.success(this.t(msg.authDeleteSuccess));
      this.props.updateGuests();
    }).catch(error => {
      message.error(error.response.data.error);
      this.props.block(false);
    })
  }

  showAuths = () => {
    return this.state.guest_auth_list.length !== 0 && this.state.guest_auth_list.map((item, index) => (
      <div key={index} className='autho-details'>
        <div className="title-bar">
          <h3>{index + 1 + "."}</h3>
          <div className='buttons-modal-guest'>
            <Button
              type="primary"
              onClick={() => {
                this.dataGuestEdit = item;
                this.editFormModal(index);
              }}
            >
              {this.t(msg.editAuth)}
            </Button>
            <Button type="danger" onClick={() => this.deleteTimeFrameGuest(index, item.timeframe_id)}>{this.t(msg.deleteAutho)}</Button>
          </div>
        </div>

        <div className="content">
          <div>
            <div className='container-auth-details'>
              {item.auth_details.split('\n').map((line, lineIndex) => (
                <p key={lineIndex} style={{ margin: 0 }}>{line}</p>
              ))}
            </div>
            {item.time_frame_authorize === "Yes" && 
              <div className='title'>
                <h3>{item.time_frame_authorize === "Yes" && this.t(msg["authorized_" + item.time_frame_authorize])}</h3> 
              </div>
            }
          </div>
        </div>  

      </div>
    ));
  }

  addAuth = (authData) => {
    SmartRequests.post(`guest_authorization/${this.state.id_guest}/auths`, authData ).then( (response) => {
      this.setState({ guest_auth_list: response.data.guest_auth_list})
      this.handleCancelModal();
      this.cont ++;
      this.dataGuestEdit = formDataAuth;
      this.props.updateGuests();
      message.success(this.t(msg.authoAddedSuccess));
    }).catch(error => {
      message.error(error.response.data.error);
      this.props.block(false);
    })
  };

  editAuth = (authData, frequency) => {
      if (this.state.typeOfPage === "edit-auth"){
        let timeframe_id = frequency === "recurrence" ? authData.timeframe_id : "";
        delete authData.timeframe_id
        authData["authorization_type"] = frequency;
        SmartRequests.put('guest_authorization/' + this.state.id_guest + '/' + timeframe_id, authData ).then( (response) => {
          this.setState({ guest_auth_list: response.data.guest_auth_list});
          this.handleCancelModal();
          this.props.updateGuests();
          message.success(this.t(msg.SuccessRequest));
        }).catch(error => {
          message.error(error.response.data.error);
          this.props.block(false);
        })
      }
  };

  checkRequiredFields = () => {
    const nameField = this.state.formData.find(item => item[0] === "guest_name");
    const identificationField = this.state.formData.find(item => item[0] === "guest_identification");

    if (nameField[1].value === "" || identificationField[1].value === "") {
        return false;
    }
    return true
  }

  continueAddAuths = (e, type_of_method, source_page) => {
    if (this.checkRequiredFields()){
      this.submitHandler(e, type_of_method, source_page);
    } else {
      message.error(this.t(msg.requiredFields))
    }
  }

  backAddGuest = () => {
    this.setState({typeOfPage: "add-guest"})
  }

  goToInvitationPage = () => {
    this.props.updateGuests();
    this.props.history.replace({pathname:`/${this.props.building}/${this.props.unit}/dashboard`})
    this.props.history.push({pathname: `/${this.props.building}/${this.props.unit}/dashboard/guest_auth/`})
  }


  render() {
    const t = this.t;
    const {formData, isModalVisible, typeOfPage} = this.state;

    let frecuencyObject = formData.find(item => item[0] === "authorization_type");
    let frequency = frecuencyObject !== undefined ? frecuencyObject[1].value : "";
    
    return (
      
      <div className="frame">
        <Spin spinning={this.state.isLoading} delay={10}>
          <Form id="CustomForm" className="custom-form frame" onSubmit={this.submitHandler}>
            {formData.map( (input, index) => this.createInput(input, index))}

            {(typeOfPage === "edit-auth") &&
              <div>
                {isModalVisible &&
                  <Modal
                    title= {t(msg.newAuthoForm)}
                    visible={isModalVisible}
                    onCancel={this.handleCancelModal}
                    footer={null}
                    className="invitations"
                  >
                    <div className='container-add-items'>
                      <ItemAccess 
                        infoItem={this.dataGuestEdit} 
                        autorization_type={frequency} 
                        action={this.action} 
                        addAuth={this.addAuth.bind(this)} 
                        editAuth={this.editAuth.bind(this)} 
                        type_of_page={typeOfPage}
                      >
                      </ItemAccess>
                    </div>
                  </Modal>
                }

                {frequency === "recurrence" &&
                  <div>
                    {this.showAuths()}
                    <div className='container-button-item' >
                      <div id="plus-add-autho" style={{"display": "flex","gap": "1rem", "cursor": "pointer","alignItems" : "center"}} onClick={() => this.showFormModal()}>
                        <div className='container-plus-circle'>
                          <PlusCircleOutlined />
                        </div>
                        <h3>{t(msg.pressToAddAuthorization)}</h3>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }

            <div className='container-buttons'>

              {typeOfPage === "add-new-guest" &&
                <div>
                  {frequency === "recurrence" 
                    ? <Button type="primary" onClick={(e) =>  this.continueAddAuths(e, "save_and_continue", "add-page") }>{t(msg.saveAndContinue)}</Button>
                    : <Button type="primary" htmlType="submit">{t(msg.save)} </Button>
                  }
                </div>
              }

              {typeOfPage === "edit-guest" && 
                <div>
                  {frequency === "recurrence" 
                    ? (
                      <div className='buttons'>
                        <Button type="primary" onClick={() => this.submitEditGuest(this.state.id_guest)}>{t(msg.saveAndBack)} </Button>
                        <Button type="primary" onClick={(e) =>  this.continueAddAuths(e, "update_and_continue", "edit-page") }>{this.t(msg.saveAndContinue)}</Button>
                      </div>

                    )
                    : <Button type="primary" onClick={() => this.submitEditGuest(this.state.id_guest)}>{t(msg.save)} </Button>
                  }
                </div>
              }

              {typeOfPage === "edit-auth" &&
                <div>
                  {frequency === "recurrence" 
                    ? <Button className="submit-button-invitations" type="primary" onClick={() => this.goToInvitationPage()}>{t(msg.close)} </Button>
                    : <Button type="primary" onClick={() => this.addAuth({"authorization_type":frequency})}>{t(msg.save)} </Button>
                  }
                </div>
              }

            </div>

          </Form>
        </Spin>
      </div>
      
    )
  }
}

// const HOCWrapper = WrappedComponent => {
//   return class extends React.Component {
//     render() {
//       const Wrapped1 = intlProps => {
//         console.log(`INTL PROPS: ${JSON.stringify(intlProps)}`)
//         const Wrapped2 = formProps => {
//           console.log(`FORM PROPS: ${JSON.stringify(formProps)}`);
//           return <WrappedComponent {...intlProps} {...formProps} {...this.props} />
//         }
//         const Wrapper2 = Form.create()(Wrapped2)
//         return <Wrapper2 />
//       }
//       const Wrapper1 = injectIntl(Wrapped1);
//       return <Wrapper1 />;
//     }
//   }
// }

export default withRouter(injectIntl(Form.create()(GuestForm)));
