import { SmartRequests } from '../../utilities'
import * as CustomStorage from '../../utilities/customStorage'

const SESSION_INFO = 'SessionInfo';
const CURRENT_BUILDING = 'CurrentBuilding';
const CURRENT_UNIT = 'CURRENT_UNIT';
const SESSION_INFO_TIMESTAMP = 'SESSION_INFO_TIMESTAMP';

var alreadyRefreshedData = false

class buildingInfo {

    isFetching = false
    listeners = [ ]
    oneTimeListeners = [ ]

    getBuildingByName = async (name) => {
        if (await this.doesBuildingExists(name)) {
            let buildings = await this.getStoredBuildings()
            return buildings.find(building => building.name === name)
        }
    }

    // Public 
    getCurrentUnit = async () => {
        let unit = await CustomStorage.getItem(CURRENT_UNIT);
        if (!unit) {
            const currentBuilding = await this.getCurrentBuilding();
            const defaultUnit = currentBuilding.units[0];
            unit = defaultUnit;
        }
        return unit;
    }

    // Public 
    setCurrentUnit = async (value) => {
        await CustomStorage.setItem(CURRENT_UNIT, value);
    }

    // Public
    retriveCurrentBuilding = async() => {
        await this.refresh();
        const currentBuilding = await this.getCurrentBuilding();
        return currentBuilding;
    }

    // Public
    getCurrentBuilding = async() => {
        let buildings = await this.getStoredBuildings();
        if (buildings.length === 0) {
            await this.refresh();
            buildings = await this.getStoredBuildings();
        }
        console.log(`All stored buildings: ${buildings}`);
        const currentBuildingName = await this.getCurrentBuildingName();
        return buildings.filter(item => item.name === currentBuildingName)[0];
    }

    // Private
    getCurrentBuildingName = async () => {
        const buildings = await this.getStoredBuildings();
        let currentBuildingName = await CustomStorage.getItem(CURRENT_BUILDING);
        if (!await this.doesBuildingExists(currentBuildingName)) {
            currentBuildingName = await this.setCurrentBuilding(buildings[0].name);
        }
        return currentBuildingName;
    }

    // Private
    doesBuildingExists = async (name) => {
        const allBuildings = await this.getStoredBuildings();
        return allBuildings.reduce((hasFoundBuilding, currentBuilding) => {
            const isTheWantedBuilding = currentBuilding.name === name;
            return hasFoundBuilding || isTheWantedBuilding;
        }, false);
    }

    // Public
    getStoredBuildings = async () => {
        const storedInfo = await this.getStoredInfo();
        return storedInfo ? storedInfo.buildings : [ ];
    }

    // Public
    getEmail = async () => {
        const info = await this.getInfo();
        return info.email;
    }

    // Public 
    getInfo = async () => {
        let info = await this.getStoredInfo();
        if (!info) {
            info = await this.refresh();
        }
        return info;
    }

    // Private
    refresh = async () => {
        if (!this.isFetching) {
            this.isFetching = true
            const data = await this.fetch().catch((e) => {
                this.isFetching = false
                this.purgeListeners(e)
                throw e
            });
            await this.storeInfo(data);
            this.isFetching = false
            await this.callAllListeners();
            alreadyRefreshedData = true
            return data;
        } else {
            return this.newOneTimeListener()
        }
    }

    // Private
    fetch = async () => {
      try {
        const response = await SmartRequests.get('/resident/ws/info')
        if (response && response.data) {
          const data = response.data;
          return data;
        }
        throw new Error('There was an error fetching the building info.')
      } catch(err) {
        throw err
      }
    }

    // Public
    dropData = async () => {
        await CustomStorage.removeItem(SESSION_INFO);
        await CustomStorage.removeItem(CURRENT_BUILDING);
        await CustomStorage.removeItem(CURRENT_UNIT);
    }

    // Private
    storeInfo = async (data) => {
        const serialyzedData = JSON.stringify(data);
        await CustomStorage.setItem(SESSION_INFO, serialyzedData);
        await this.refreshTimestamp();
    }

    // Public
    getStoredInfo = async () => {
        const storedData = await CustomStorage.getItem(SESSION_INFO);
        return JSON.parse(storedData);
    }

    // Private
    setCurrentBuilding = async (name) => {
        await CustomStorage.setItem(CURRENT_BUILDING, name);
        return name;
    }

    // Private
    refreshTimestamp = async () => {
        const timestamp = Date.now();
        await CustomStorage.setItem(SESSION_INFO_TIMESTAMP, timestamp);
    }

    // Public
    listen = (callback) => {
        if (typeof callback === 'function') {
            this.listeners.push(callback)
            if (alreadyRefreshedData) {
                callback()  
            }
        }
    }

    // Private 
    callAllListeners = async () => {
        const info = await this.getInfo()
        this.oneTimeListeners.forEach(resolve => {
            resolve(info)
        })
        this.oneTimeListeners = [ ]
        this.listeners.forEach((callback) => {
            callback();
        });
    }

    purgeListeners = async (error) => {
        this.oneTimeListeners.forEach(resolve => {
            resolve(new Error(error))
        })
        this.oneTimeListeners = [ ]
    }

    // Private 
    newOneTimeListener = () => {
        const _this = this
        const listener = new Promise(function (res, rej) {
            _this.oneTimeListeners.push(res.bind(this))
        })
        return listener
    }
}

const BuildingInfo = new buildingInfo();
export default BuildingInfo;
