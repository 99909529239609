import React from 'react';
import { SmartRequests } from '../../../utilities';
import PageUl from '../../../components/PageUl';
import { Button, Radio, Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import { PaginationControl, reloadIfNeeded } from '../../../components/Pagination.js';
import { debounce } from 'lodash';
import { injectIntl } from "react-intl";
import Guest from './Guest';
import { Route, withRouter } from 'react-router-dom';
import TransitionSwitch from '../../../components/TransitionSwitch';
import PageFrame from '../../../components/PageFrame';
import Header from '../../PageView/Header';
import GuestForm from './GuestForm';
import msg from './messages';
import { PlusCircleOutlined } from '@ant-design/icons';




class Invitations extends React.Component  {

    constructor(props) {
      super(props)
      this.state = {
        pageOffset: 0,
        searching: true,
    }
      this.formCreateAndEditGuest= `guest_name, textbox, required\r\nguest_identification, textbox, required\r\nguest_phone, textbox,\r\nguest_email, textbox,\r\ntype_of_guest, [family,friend,service,client],\r\nauthorization_type, [always,not_allowed,recurrence]`;
      this.formEditAutho=`authorization_type, [always,not_allowed,recurrence]`;

      this.guests = [];
      this.pageSize = 10;
      this.debouncedReloadIfNeeded = debounce( reloadIfNeeded.bind( this, 'guest_authorization?', this.setState.bind(this)) , 300);
    }

    componentDidMount() {
        console.log('componentDidMount: mounting invitations');
        this.t = this.props.intl.formatMessage;
        this.guest_info();
        if (this.props.hidePageText) {
            this.props.hidePageText()
        }
    }

    guest_info(){
        SmartRequests.get('guest_authorization?page_size=' + this.pageSize).then( resp => {
            if (resp.status === 200) {
                this.setState({searchResults: resp.data, searching: false})
            }
        }).catch( err => { 
            console.log("Error: " + err);
        });
    }

    updateGuests = () => {
        this.reload= true
        this.debouncedReloadIfNeeded();
    }

    reloadTimeFrame(id){
        SmartRequests.get(`guest_authorization/${this.idCli}/${this.t_id}`).then( resp => {
            if (resp.status === 200) {
                this.setState(prevState => {
                    const updatedArray = [...prevState.itemAccessForDay];
                    updatedArray[id] = resp.data.timeframe;
                    return { itemAccessForDay: updatedArray };
                });
            }
        }).catch( err => { 
            console.log("Error: " + err);
        });
    }

    deleteGuest = (guest_id) => {
        SmartRequests.Delete('guest_authorization/' + guest_id).then( resp => {
            if (resp.status === 200) {
                this.reload = true;
                this.setState(oldState => {
                    const updatedResults = oldState.searchResults.results.slice().filter(el => el.id !== guest_id);
                    const totalElements = oldState.searchResults.total_nof_elements - 1;
                    const updatedGuests = { ...oldState.searchResults, results: updatedResults , total_nof_elements: totalElements};
                    return { searchResults: updatedGuests };
                });
            }
        }).catch(err => {
            console.log("Error: " + err) 
        })
    }


    setSearchQuery(e) {
        this.query = e.target.value;
        this.setState({ pageOffset: 0 });
        this.debouncedReloadIfNeeded();
    }

    updateOffset = (newOffset) => {
        this.setState({pageOffset: newOffset})
    }

    renderPageFrameWithForm = (type_of_page, props) => {
        let form = "", title = "", source_page = "";
        this.t = this.props.intl.formatMessage;
        if (type_of_page === "add-new-guest"){
            form = this.formCreateAndEditGuest;
            title = this.t(msg.AddGuest);
        } else if (type_of_page === "edit-guest"){
            form = this.formCreateAndEditGuest;
            title = this.t(msg.editGuest);
        } else if (type_of_page === "edit-auth"){
            form = this.formEditAutho;
            if (props.location.state){
                let { guest_name } = props.location.state.guest;
                title = `${this.t(msg.editGuestAuthos)} ${guest_name || this.t(msg.guest)}`;
                source_page = props.location.state.source_page;
            } else {
                title = `${this.t(msg.editGuestAuthos)} ${this.t(msg.guest)}`;
                source_page = "edit-page";
            }
        } 

        return (
            <PageFrame>
                <Header pageInfo={{"content": null}}>{title}</Header>
                <GuestForm
                    block={this.props.methods.block}
                    formRaw={form} 
                    unit={this.props.unit} 
                    type_of_page={type_of_page}
                    updateGuests={this.updateGuests}
                    building={this.props.reqData.building}
                    source_page={source_page}
                />
            </PageFrame>
        );
    }


  render() {
    const t = this.props.intl.formatMessage;

    if (this.state.searchResults) {
        this.guests = this.state.searchResults
    }

    return (

        <div className='invitations'>

            <div className='container-add-guest'>
                <div className='add-guest'>
                    <Button 
                        id='button-add-new-guest'
                        type='primary'
                        onClick={() => {
                            this.props.history.push({
                                pathname: `/${this.props.reqData.building}/${this.props.unit}/dashboard/guest_auth/add-new-guest`,
                            });
                        }}
                    >
                        <PlusCircleOutlined/>
                        {t(msg.AddNewGuest)}
                    </Button>

                </div>
                
            </div>
            
            <TransitionSwitch level={this.props.level+ 3}>
                <Route path={`/:building/:unit/dashboard/guest_auth/add-new-guest`}>
                    {this.renderPageFrameWithForm("add-new-guest", this.props)}
                </Route>
                <Route exact path={`/:building/:unit/dashboard/guest_auth/edit-guest/:id_guest/edit-auth`}>
                    {this.renderPageFrameWithForm("edit-auth", this.props)}
                </Route>   
                <Route exact path={`/:building/:unit/dashboard/guest_auth/edit-guest/:id_guest`}>
                    {this.renderPageFrameWithForm("edit-guest", this.props)}
                </Route> 
            </TransitionSwitch>

            <PageUl >
                <div className="filterBar">
                    <Search
                        id='input-search-guests'
                        placeholder={t(msg.Search)}
                        onChange={this.setSearchQuery.bind(this)}
                        style={{ width: 120 }}
                        allowClear
                    />
                </div>

                <Spin spinning={this.state.searching} delay={500}>
                {("results" in this.guests) && (this.guests.results.length !== 0) && (this.guests.page_size > 0)
                    ? (
                        <div className='invitations'>
                            <PaginationControl t={this.props.intl.formatMessage} pageOffset={this.state.pageOffset} updateOffset={this.updateOffset} debouncedReloadIfNeeded={this.debouncedReloadIfNeeded.bind(this)} pageSize={this.pageSize} elements={this.guests}/>
                            {this.guests.results.map((guest, index) => <Guest key={index} infoGuest={guest} deleteGuest={this.deleteGuest.bind(this)} building={this.props.reqData.building} unit={this.props.unit} methods={this.props.methods} page={this.props.page}/>)}
                        </div>
                    ) : (   
                        <li className='li-request'> 
                            <p className="body"> {t(msg.noGuests)} </p>
                        </li>
                    )
                }</Spin>
                {<br></br>}
                {<br></br>}
            </PageUl>
        </div>  

    );
  }
}

export default injectIntl(withRouter(Invitations));
