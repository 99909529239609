import React from 'react';
import { injectIntl } from "react-intl";
import { Route, withRouter } from 'react-router-dom';
import { PaginationControl, reloadIfNeeded } from '../../../components/Pagination';
import { Button, Radio, Spin} from 'antd';
import Search from 'antd/lib/input/Search';
import PageUl from '../../../components/PageUl';
import { debounce } from 'lodash';
import msg from './messages';
import ItemPoll from './ItemPoll';
import PollViewer from './PollViewer';
import TransitionSwitch from '../../../components/TransitionSwitch';

class Polls extends React.Component  {
    constructor(props) {
        super(props)
        this.state = {
            pageOffset: 0,
            searching: false,
        }
        this.category = 'sent';
        this.pageSize = 10;
        this.debouncedReloadIfNeeded = debounce( reloadIfNeeded.bind( this, 'poll?', this.setState.bind(this)) , 300);

    }

    componentDidMount() {
        console.log('Mounting Polls');
    }

    setSearchQuery(e) {
        this.query = e.target.value;
        this.setState({ pageOffset: 0 });
        this.debouncedReloadIfNeeded();
    }

    updateOffset = (newOffset) => {
        this.setState({pageOffset: newOffset})
    }

    setSearchCategory = (e) => {
        this.category = e.target.value;
        this.setState({ pageOffset: 0 });
        this.debouncedReloadIfNeeded();
    }

    updatePolls = (id_poll) => {
        const indexToUpdate = this.polls.results.findIndex((poll) => poll.id === parseInt(id_poll, 10));
        if (indexToUpdate !== -1) {
            this.polls.results[indexToUpdate].answered = 1;
        } else {
            console.error(`Element with ID ${id_poll} not found`);
        }
    }
 
  render() {
    const t = this.props.intl.formatMessage;
    this.polls = this.props.polls;
    if (this.state.searchResults) {
        this.polls = this.state.searchResults;
    }

    return (
        <div className='polls'>
            <PageUl>
                <div className="filterBar" style={{margin: 0}}>
                    <Radio.Group 
                        defaultValue="sent" 
                        buttonStyle="solid"
                        onChange={this.setSearchCategory.bind(this)}
                    >
                        <Radio.Button value="sent">{t(msg.inCourse)}</Radio.Button>
                        <Radio.Button value="finished">{t(msg.finished)}</Radio.Button>
                    </Radio.Group>
                    &nbsp;
                    <Search
                        placeholder={t(msg.Search)}
                        onChange={this.setSearchQuery.bind(this)}
                        style={{ width: 120 }}
                        allowClear
                    />
                </div>

                <Spin spinning={this.state.searching} delay={500}>
                {("results" in this.polls) && (this.polls.results.length !== 0) && (this.polls.page_size > 0)
                    ? (
                        <div className='container-polls-items'>
                            <PaginationControl t={this.props.intl.formatMessage} pageOffset={this.state.pageOffset} updateOffset={this.updateOffset} debouncedReloadIfNeeded={this.debouncedReloadIfNeeded.bind(this)} pageSize={this.pageSize} elements={this.polls}/>
                            {this.polls.results.map((poll, index) => 
                                <ItemPoll key={index} dataPoll={poll} building={this.props.reqData.building} unit={this.props.unit} methods={this.props.methods} page={this.props.page}/>
                            )}
                        </div>
                    ) : (   
                        <li className="li-request"> 
                            <p > {t(msg.NoPollsFound)} </p>
                        </li>
                    )
                }</Spin>
                {<br></br>}
                {<br></br>}
            </PageUl>

            <TransitionSwitch level={this.props.level + 1}>
                <Route
                    path={`/:building/:unit/dashboard/polls/:id_poll`}
                    render={(props) => {
                        
                        return (
                        <PollViewer 
                            id_poll={props.match.params.id_poll} 
                            updatePolls={this.updatePolls} 
                            subtractVoteForNotResponding={this.props.methods.subtractVoteForNotResponding}
                        />)
                    }}
                />
            </TransitionSwitch>
        </div>  
    );
  }
}

export default injectIntl(withRouter(Polls));