import React from 'react'
import { ZoomInOutlined } from '@ant-design/icons'

let setRendererImage = (src) => { console.log(`Images renderer not yet mounted! ${src}`) }

export class ZoomableImage extends React.PureComponent {
    _enable = () => {
        setRendererImage(this.props.src)
    }
    
    _disable = () => {
        setRendererImage(null)
    }

    componentWillMount = () => {
        this.ref = React.createRef()
    }

    componentDidMount = () => {
        this.ref.current.addEventListener('click', this._enable)
    }

    render = () => {
        return (
            <div ref={this.ref} className="zoomable-image">
                <img src={this.props.src} alt={this.props.alt} />
                <span className="zoomable-visible-flag"> <ZoomInOutlined style={{color: 'white'}} /> </span>
            </div>
        )
    }
}

export function setRendererInstance(instance) {
    setRendererImage = instance._setRendererImage
}
