import React from 'react';
import * as utils from '../../utilities/utils.js'
import { Route, Redirect, withRouter} from 'react-router-dom'
import { message } from 'antd'
import * as helper from './helpers.js'
import Square from '../../components/Square.js'
import PageFrame from '../../components/PageFrame.js'
import Board from '../../components/Board.js'
import Fetching from '../../components/Fetching.js'
import TransitionSwitch from '../../components/TransitionSwitch.js'
import PageView from '../PageView'
import ErrorPage from '../Error'
import Settings from '../Settings'
import HiddenMenu from './HiddenMenu.js'
import Header from './Header.js'
import Notifications from './Notifications'
import { injectIntl } from "react-intl";
import msg from './messages'
import BuildingInfo from '../Main/buildingInfo';
import ChooseAnotherUnitOrLogoutModal from './ChooseAnotherUnitOrLogoutModal.js';
import Pages from './pages';
import cordovaNotificationHandler from '../Main/cordovaCommunication/cordovaNotificationHandler.js';
import { CordovaCommunication } from '../Main/cordovaCommunication/index.js';
import cookieStorage from '../Main/cookieStorage.js';
import Updates from './Updates';
import Scrollable from '../../components/Scrollable'
import * as mqttClient from './mqttClient'
import * as PageData from './pageData.js';
import { resetAllCameraConnections } from '../Pages/Camera'
import HelpRequested from '../../components/HelpRequested.js';
import {Mutex} from 'async-mutex';
const BG = 'bg'
const FG = 'fg'

const TIMEOUT_30_MIN = 30*60*1000;
const TIMEOUT_30_SEC = 30*1000;
const TIMEOUT_1_DAY = 24*60*60*1000;

const LEVEL=5
const mutex = new Mutex();

// This component manages the pages.
class DashBoard extends React.Component{

  fetchingIntervals = [];
  state = {
    loading: true,
    hiddenMenu: true,
    pages: [ ],
    messages:[ ],
    deliveries:[ ],
    vehicles:[ ],
    reservations:[ ],
    weather:[ ],
    lastWeatherUpdate:'...',
    requests:[ ],
    units:[ ],
    building:'',
    unit:'',
    reservables: [ ],
    user:'',
    tags: { },
    today: undefined,
    carWash: { },
    newMqttMessage: false,
    fetching: true,
    blocked: false,
    unrecoverableError: false,
    activeEmergency: null,
    polls: [ ],
    pageSize: 5,
    intervalsSet: false,
  }


  ClearForceExitTimout = () => {
    if (this.InitTimeout){
      clearTimeout(this.InitTimeout)
      this.setState({timedOut: false})
    }
  }

  setLoading = (loading) => {
    this.setState({
      loading: loading
    })
  }

  componentDidMount = () => {

    console.log('mounting dashboard')
    this.t = this.props.intl.formatMessage
    this.stopListeningDimmerState = CordovaCommunication.setDimmerizedScreenHandler((e) => {
      this.refreshOnUndimmerize(e); 
      console.log('Refresh on undimerize');
    });
    BuildingInfo.listen(this.refreshBuildings);
    CordovaCommunication.setHandler('lifecycle', this.lifecycleHandler)
    CordovaCommunication.openChannel('lifecycle')
    cordovaNotificationHandler.setUnitChangeFunction(this.setUnit)
    this.setupPageDataPoller()
  }

  componentDidUpdate = prevProps => {
    if (this.props.token !== prevProps.token) {
      const sendToken = this.props.token.sendToServer;
      if (this.pushTokenInterval) clearInterval(this.pushTokenInterval);
      this.pushTokenInterval = setInterval(sendToken, TIMEOUT_1_DAY);
      sendToken();
    }
  }


  setupPageDataPoller = async () =>  {
    const pageCache = new Pages(this.props.building, this.props.unit);
    let pages = await pageCache.get();
    pages = pages.map((page) => page.page.name); //only visible pages
    this.pagePollers = PageData.instantiateOneOfEach(this, pages, this.setLoading)
  }

  lifecycleHandler = (state) => {
    // in order to handle background/foreground changes
    if (state === FG) {
      // just moved to foreground, refresh all data
      this.clearIntervalsForFetchingFunctions()
      this.setIntervalsForFetchingFunctions()
      resetAllCameraConnections()
    } else if (state === BG) {
      // intervals aint needed when at background
      this.clearIntervalsForFetchingFunctions()
    }
  }

  refreshOnUndimmerize = (event) => {
    const isDimmerized = event.isDimmerized;
    if (isDimmerized) {
      this.clearIntervalsForFetchingFunctions();
    } else {
      this.clearIntervalsForFetchingFunctions();
      this.setIntervalsForFetchingFunctions();
    }
  }
  
  block = b => {
    if (this.FetchingTimout){
      clearTimeout(this.FetchingTimout)
    }
    if (b) {
      this.FetchingTimout = setTimeout(() => {
        this.setState({blocked:false})
        message.error(this.t(msg.ErrorTimeout))
      }, 10000);
    }
    this.setState({blocked:b})
  }

  refreshBuildings = async () => {
    const info = await BuildingInfo.getInfo();
    this.setState({ 
      units: info.buildings.reduce(((curr, act) => curr.concat(act.units.map(u => [act, u, info.email]))), []),
    })
    await this.setUnit(await BuildingInfo.getCurrentBuilding(), this.props.unit, await BuildingInfo.getEmail())
  }

  setupMQTTClient = async () => {
    console.log("Setting up MQTT client")
    await mqttClient.setup()
    const forceRefresh = true
    const mqttReload = true
    const buildingId = (await BuildingInfo.getCurrentBuilding()).id
    const unit = await BuildingInfo.getCurrentUnit()
    // subscribe to all topics
    const prefix = buildingId + '/' + unit + '/+/service/'

    var handlerMqttPageMessages = (rawTopic, message) => {
      console.log('MQTT message received: ' + rawTopic + ' ' + message)
      const topic = rawTopic.split('/service/')[1]
      const pageDataPoll = this.pagePollers.find(poller => poller.nameMatches(topic) || (poller.nameMatches('custom') && topic.nameMatches('requests')))
      if(pageDataPoll && pageDataPoll.refresh && (topic==='requests' || topic==='deliveries' || topic==='expenses_info')){
        console.log('Refreshing ' + topic)
        this.setState({newMqttMessage: true,
          mqttTopicMessage: topic})} // this topics require a special reload
      else{
        pageDataPoll.refresh({forced:forceRefresh, mqttReload:mqttReload, unit:unit}) 
      }
    }

    PageData.MQTT_TOPICS.forEach(topic => {
        console.log('Subscribing to ' + prefix + topic)
        mqttClient.subscribeToTopic(
          prefix + topic,
          handlerMqttPageMessages
        )
      })
      
    mqttClient.onConnect(() => {
        this.stopFetchDataOnPushNotifications()
        console.log('Connected to real time synchronization')
        this.setIntervalsForFetchingFunctions(forceRefresh)
      })

    mqttClient.onError((error) => {
        console.log('MQTT ERROR: ' + JSON.stringify(error));
        console.log('There was an error connecting to real time synchronization')
        this.fetchDataOnPushNotifications();
        this.setIntervalsForFetchingFunctions();
      })   
  }

  setActiveEmergency = (data) => {
    this.setState({activeEmergency: data})
  }

  setUnit = async (building, unit, username) => {
    this.setState({
      hiddenMenu: true,
      unrecoverableError: false
    })
    await BuildingInfo.setCurrentBuilding(building.name);
    await this.readPages(building.name, unit).then(() => {
      this.setState({
        building: building,
        unit: unit,
        fetching: false,
      });
    }).catch(error => {
      let errorMessage = `Could not fetch pages: ${error}`
      console.log(errorMessage)
      message.error(errorMessage)
      this.setState({unrecoverableError: true})
    });
    await BuildingInfo.setCurrentUnit(unit)
    this.clearIntervalsForFetchingFunctions()
    await this.setupMQTTClient();
  }

  setIntervalsForFetchingFunctions = async (forced = false) => {
    let release;
    try {
      release = await mutex.acquire();
      if (!this.intervalsSet) {
        this.intervalsSet = true;
        console.log("SETTING INTERVALS");
        this.fetchingIntervals = await Promise.all(this.pagePollers.map(poller => {
          const pageType = poller._pageName;
          if (pageType !== 'requests') {
            return this.setIntervalForSingleFunction(pageType, forced);
          }
        }).filter(Boolean));
        await this.refreshPages();
        this.fetchingIntervals.push(setInterval(this.refreshPages, TIMEOUT_30_MIN));
      }
    } catch (error) {
        console.error("An error occurred while setting intervals:", error);
        // Handle the error appropriately
      } finally {
        if (release) {
          release();
        }
      }
  }

  setIntervalForSingleFunction = async (pageType, forced = false) => {
    const bindedCallback = await this.fetchPageData(pageType, forced);
    return setInterval(bindedCallback, TIMEOUT_30_MIN);
  }

  pageMessageReceivedCallback = () => { 
    this.setState({newMqttMessage: false})
  }

  fetchDataOnPushNotifications = () => {
    console.log('Listening for push notifications as fallback of MQTT.');
    const notifications = cordovaNotificationHandler.getNotifications();
    this.stopFetchDataOnPushNotifications()
    this.unsubscribeNotifications = notifications((notifications) => {
      if (notifications.length === 0) return;
      const notification = notifications[notifications.length - 1];
      const data = notification.additionalData;
      const pageType = data.page;
      this.fetchPageData(pageType);
    });
  }

  stopFetchDataOnPushNotifications = () => {
    if (this.unsubscribeNotifications) {
      this.unsubscribeNotifications()
      this.unsubscribeNotifications = null
    }
  }

  fetchPageData = async (pageType, force = false,  pageName=undefined) => {
    const pageDataPoller = this.pagePollers.find(page => page.nameMatches(pageType) || (pageType=='custom' && page.nameMatches('requests'))) || { refresh: () => {console.log(`Opening ${pageType}, but won't refresh it`)} }
    console.log('the page is ' +pageName)
    if(pageType==='custom' && pageName){
      pageDataPoller.refresh({forced: force, tag: pageName})
    }else{
      pageDataPoller.refresh({forced: force})
    }
    return pageDataPoller.refresh.bind(this, force)
  }

  refreshPages = async () => {
    const pageCache = new Pages(this.state.building.name, this.state.unit);
    await pageCache.fetch();
    await this.readPages(this.state.building.name, this.state.unit)
  }

  readPages = async (buildingName, unit) => {
    const pageCache = new Pages(buildingName, unit);
    const pages = await pageCache.get();
    const tags = await pageCache.getTags();
    this.setState({
      pages: pages,
      tags: tags,
    });
  } 

  componentWillUnmount = () => {
    this.clearIntervalsForFetchingFunctions();
    if (this.client){
      this.client.end()
    }
    //this.stopListeningDimmerState && this.stopListeningDimmerState();
    if (this.pushTokenInterval) clearInterval(this.pushTokenInterval);
  }

  clearIntervalsForFetchingFunctions = () => {
    this.fetchingIntervals.forEach( timmer => clearInterval(timmer) )
    this.intervalsSet = false;
  }

  toggleMessage = msgId => {
    this.setState( oldState => {
      let newMessages = oldState.messages.slice()
      let msg = newMessages.find(msg => msg.id === msgId)
      msg.status = (msg.status === 0 ? 1:0)
      return {messages:newMessages}
    })
  }

  changeVehicleState = (vehicleId, state) => {
    this.setState( oldState => {
      let newVehicles = oldState.vehicles.slice()
      let vehicle = newVehicles.find(vehicle => vehicle.id === vehicleId)
      vehicle.state = state
      return {vehicles:newVehicles}
    })
  }

  addRequest = req => {
    this.setState( oldState =>  {
        let newRequests = oldState.requests.slice()
        newRequests.unshift(req)
        return {requests: newRequests} 
      }
    )
  }

  removeRequest = reqId => {
    this.setState( oldState =>  { return {requests:  oldState.requests.slice().filter( el => el.id !== reqId )} } )
  }

  addReservation = res => {
    this.setState( oldState =>  {
        let newReservations = oldState.reservations.slice()
        helper.insertReservation(newReservations, res)
        return {reservations: newReservations} 
      }
    )
  }

  removeReservation = resId => {
    this.setState( oldState =>  { return {reservations:  oldState.reservations.slice().filter( el => el.id !== resId )} } )
  }

  updateReservation = (resId, res) => {
    this.setState( oldState =>  {
        let newReservations = oldState.reservations.slice().filter( el => el.id !== resId )
        helper.insertReservation(newReservations, res)
        return {reservations: newReservations} 
      }
    )
  }

  subtractVoteForNotResponding = () => {
    this.setState({polls: {...this.state.polls, unanswered: this.state.polls.unanswered - 1}})
  }

  toggleMenu = () => {
    this.setState(oldState => this.setState({hiddenMenu: !oldState.hiddenMenu}))
  }

  logout = () => {
    mqttClient.disconnect();
    this.props.logoutFunction();
  }

  render(){

    const setUnit = async (building, unit, username) => {
      this.setState({ fetching: true });
      this.props.history.push('/' + building.name + '/' + unit + '/dashboard/');
      await this.setUnit(building, unit, username);
    }

    if (this.state.fetching){
      return [
        <Fetching enabled key={"fetching"}/>,
        <ChooseAnotherUnitOrLogoutModal key={"anotherUnit"} visible={this.state.unrecoverableError} units={this.state.units} setUnit={setUnit} logout={this.logout} />
      ]
    }
    return (
      <div id='theme-container' className={this.state.building.style}>
        <div className='dashboard' >
          <Scrollable>
            <Fetching enabled={this.state.blocked} light />
            <Header tpMode={this.props.tpMode} building={this.state.building} toggleMenu={this.toggleMenu} hidden={this.state.hiddenMenu} showUnit={(this.state.units.length > 1)? this.state.unit : ''} /> 
            <Updates building={this.state.building.name} unit={this.state.unit}/>
            { this.props.tpMode? '': 
              <HiddenMenu logoutFunction={this.logout} hidden={this.state.hiddenMenu} units={this.state.units} setUnit={setUnit}/>
            }
            <Board>
              { 
                this.state.pages.map( 
                  p => {
                    return  <Square 
                          level={LEVEL}
                          title={(p.page.type === 'weather' && this.state.today )? this.state.today.text : p.page.title} 
                          name={p.page.name} 
                          type={p.page.type}  
                          key={p.page.id}
                          className={p.page.name + ' ' + (p.page.type === 'weather'? (this.state.today? this.state.today.conditions : 'no-weather') : '' )}
                          widget={
                            helper.WIDGET_FUNCTIONS[p.page.type]? helper.WIDGET_FUNCTIONS[p.page.type].bind(this)() : ''
                          }
                          pageData={p.page.data}
                        />}
                )
              }
            </Board>
            <TransitionSwitch level={LEVEL} >
            {

                this.state.pages.map(
                  p =>  {
                    return <Route key={p.page.id} path={'/:building/:unit/dashboard/' + p.page.name + '/:id_req?'} render={({match}) => {
                      let onLoad = this.fetchPageData.bind(this, p.page.type, false, p.page.name)
                      if (p.page.type === 'valet') {
                        onLoad = () => {
                          this.fetchPageData('valet')
                          this.fetchPageData('valet_carwash')
                        }
                      }
                      return <PageView 
                        match={match}
                        level={LEVEL}
                        page={p.page} 
                        subPages={p.subPages} 
                        unit={this.state.unit} 
                        reqData={{unit: this.state.unit, building: this.state.building.name}}
                        onLoad={onLoad}
                        url={'request/' + p.page.id}
                        loading={this.state.loading}
                        data={{
                          pages: this.state.pages,
                          newMqttMessage: this.state.newMqttMessage,
                          mqttTopicMessage: this.state.mqttTopicMessage,
                          messages: this.state.messages,
                          deliveries: this.state.deliveries,
                          vehicles: this.state.vehicles,
                          carWash: this.state.carWash,
                          reservations: this.state.reservations,
                          weather: this.state.weather,
                          lastWeatherUpdate: this.state.lastWeatherUpdate,
                          requests: this.state.requests,
                          reservables:this.state.reservables,
                          tags: this.state.tags,
                          today: this.state.today,
                          hasNoCars: this.state.hasNoCars,
                          activeEmergency: this.state.activeEmergency,
                          polls: this.state.polls,
                        }} 
                        methods={{
                          block:this.block,
                          toggleMessage: this.toggleMessage,
                          addRequest: this.addRequest,
                          removeRequest: this.removeRequest,
                          changeVehicleState: this.changeVehicleState,
                          addReservation: this.addReservation,
                          removeReservation: this.removeReservation,
                          updateReservation: this.updateReservation,
                          pageMessageReceivedCallback: this.pageMessageReceivedCallback,
                          setActiveEmergency: this.setActiveEmergency,
                          subtractVoteForNotResponding: this.subtractVoteForNotResponding,
                        }}
                      />
                    }
                  }/>
                }
                )
              }
              <Route path={'/:building/:unit/dashboard/settings'} render={() => 
                <PageFrame className='settings' > 
                  <Settings 
                    reqData={{unit: this.state.unit, building: this.state.building.name}} 
                    building={this.state.building} 
                    unit={this.state.unit} 
                    changeLocale={this.props.changeLocale}
                    block={this.block}
                    tpMode={this.props.tpMode}
                    isDevice={this.props.isDevice}
                  /> 
                </PageFrame> 
              }/>
              <Route path={'/:building/:unit/dashboard/:wrongPageName'} render={() => <Redirect to=".." />} />
            </TransitionSwitch>
          </Scrollable>
          
        </div>
        {this.state.activeEmergency != null ? <HelpRequested building={this.state.building} unit={this.state.unit}></HelpRequested> : null}


        <Notifications />
      </div>
    )
  }
}

export default DashBoard =  injectIntl(withRouter(DashBoard))
